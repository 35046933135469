import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1295.000000 903.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,903.000000) scale(0.100000,-0.100000)">



<path d="M6214 5940 c-413 -75 -696 -404 -721 -841 -16 -281 82 -539 275 -721
33 -32 64 -58 69 -58 4 0 70 124 146 275 l137 275 140 0 140 0 0 59 c0 89 -2
91 -76 91 -40 0 -64 4 -64 11 0 9 210 348 307 495 l16 25 -132 -3 -133 -3 -47
-90 c-27 -49 -135 -260 -240 -467 -106 -208 -196 -378 -201 -378 -14 0 -78
134 -109 226 -106 320 -39 608 192 820 174 159 412 237 725 237 89 0 161 2
159 4 -2 2 -44 12 -93 24 -101 23 -401 35 -490 19z"/>
<path d="M6518 5392 l-95 -149 -8 -287 -7 -287 342 7 342 7 -35 -69 c-108
-214 -287 -361 -514 -421 -84 -22 -120 -25 -253 -26 -85 0 -191 5 -234 11 -44
7 -81 10 -83 8 -7 -6 207 -73 297 -92 59 -13 127 -18 225 -18 164 0 248 19
379 87 140 72 301 250 395 434 38 77 91 208 91 228 0 13 -51 15 -377 15 l-376
0 6 350 c4 193 6 350 4 350 -2 0 -46 -67 -99 -148z"/>
<path d="M4610 4191 c-51 -16 -126 -60 -158 -94 -31 -32 -32 -36 -16 -45 15
-8 26 -4 53 20 125 110 316 110 412 1 29 -33 32 -35 47 -19 16 16 14 21 -36
66 -36 34 -69 54 -104 65 -54 16 -155 19 -198 6z"/>
<path d="M4585 4096 c-27 -13 -61 -36 -75 -50 -25 -27 -25 -28 -5 -42 17 -12
20 -12 27 1 5 8 30 27 56 42 84 49 199 36 272 -32 23 -22 27 -22 45 -9 20 15
19 16 -18 49 -78 68 -208 85 -302 41z"/>
<path d="M4648 4040 c-39 -12 -95 -48 -102 -65 -3 -8 3 -19 13 -25 14 -7 23
-5 36 10 43 48 150 51 208 7 28 -21 36 -23 50 -12 16 11 15 15 -17 43 -21 18
-54 34 -86 41 -58 12 -64 13 -102 1z"/>
<path d="M4197 3918 c-15 -14 -75 -142 -154 -328 -72 -168 -133 -313 -136
-323 -9 -25 9 -64 33 -72 46 -14 70 2 100 70 l29 65 166 0 166 0 29 -65 c22
-48 35 -66 54 -71 36 -9 66 4 77 33 8 22 -10 72 -131 352 -159 370 -175 393
-233 339z m107 -350 l45 -108 -115 0 -114 0 16 38 c8 20 34 82 57 136 41 99
41 99 53 70 7 -16 33 -77 58 -136z"/>
<path d="M5337 3922 c-15 -16 -17 -53 -17 -306 0 -268 1 -289 20 -326 39 -77
164 -127 210 -85 41 37 11 115 -43 115 -13 0 -32 6 -43 14 -18 14 -19 31 -22
292 -3 239 -5 281 -19 296 -21 23 -65 23 -86 0z"/>
<path d="M6574 3932 c-31 -20 -34 -54 -34 -370 0 -308 1 -320 21 -346 25 -31
61 -34 89 -6 16 16 20 33 20 83 0 55 3 67 25 87 l24 23 68 -74 c116 -126 130
-139 155 -139 26 0 58 27 58 49 0 7 13 -1 29 -18 l29 -31 129 0 c192 1 269 25
358 115 59 58 91 118 106 200 28 152 -62 320 -211 393 -53 26 -60 27 -230 27
l-174 0 -13 -25 c-9 -18 -13 -106 -13 -335 -1 -194 -5 -303 -10 -290 -5 11
-50 63 -100 115 l-90 95 80 78 c86 84 99 117 62 150 -36 33 -60 21 -171 -85
l-106 -101 -5 190 c-4 140 -9 194 -19 206 -14 17 -57 22 -77 9z m801 -141
c165 -75 207 -277 84 -400 -53 -53 -110 -74 -221 -79 l-98 -5 0 252 0 251 98
0 c72 0 107 -5 137 -19z"/>
<path d="M4651 3904 c-12 -15 -21 -35 -21 -46 0 -24 44 -68 68 -68 33 0 72 38
72 71 0 65 -80 93 -119 43z"/>
<path d="M5682 3914 c-15 -10 -22 -25 -22 -49 0 -54 48 -87 92 -64 31 16 38
28 38 66 0 35 -29 63 -65 63 -11 0 -31 -7 -43 -16z"/>
<path d="M8404 3875 l-25 -25 3 -228 c3 -206 5 -231 24 -273 46 -100 140 -160
248 -160 139 0 254 94 188 154 -25 23 -50 21 -97 -8 -76 -47 -170 -26 -215 47
-16 27 -20 51 -20 131 l0 97 113 0 c125 0 147 9 147 62 0 48 -25 58 -148 58
l-112 0 0 60 c0 51 -4 65 -25 85 -31 32 -50 31 -81 0z"/>
<path d="M6100 3731 c-76 -25 -136 -78 -169 -151 -18 -39 -21 -66 -21 -201 0
-139 2 -158 18 -172 24 -22 65 -22 85 1 12 13 16 48 19 162 2 120 6 150 22
176 38 63 120 83 183 44 54 -32 63 -63 63 -210 0 -160 11 -190 72 -190 49 0
58 28 58 185 0 159 -11 208 -61 268 -47 57 -109 89 -182 93 -34 2 -73 0 -87
-5z"/>
<path d="M7902 3722 c-114 -41 -185 -142 -184 -263 1 -194 205 -329 376 -248
l47 22 24 -23 c29 -28 67 -21 91 15 14 21 16 48 12 163 -3 126 -6 142 -31 194
-62 126 -205 186 -335 140z m166 -132 c127 -78 74 -280 -73 -280 -49 0 -102
26 -128 62 -30 40 -34 124 -8 166 29 45 77 72 131 72 27 0 58 -8 78 -20z"/>
<path d="M9108 3727 c-173 -49 -259 -255 -168 -403 28 -44 92 -100 137 -119
55 -23 151 -21 206 5 l46 21 20 -20 c26 -26 52 -27 80 -3 19 16 21 28 22 157
1 77 -4 158 -10 180 -38 135 -195 220 -333 182z m150 -137 c51 -31 74 -76 69
-136 -8 -114 -116 -177 -217 -126 -139 69 -86 282 70 282 27 0 58 -8 78 -20z"/>
<path d="M4656 3708 c-13 -19 -16 -60 -16 -250 0 -215 1 -229 20 -248 28 -28
64 -25 89 6 20 25 21 39 21 235 0 202 -6 254 -33 271 -24 15 -66 8 -81 -14z"/>
<path d="M5073 3714 c-59 -22 -127 -86 -159 -151 -25 -50 -28 -69 -32 -187 -4
-123 -3 -133 18 -158 24 -31 55 -36 87 -13 19 14 21 25 25 153 3 134 4 140 31
175 32 42 65 61 122 72 50 8 75 30 75 65 0 57 -73 77 -167 44z"/>
<path d="M5680 3710 c-19 -19 -20 -33 -20 -248 0 -216 1 -229 20 -250 12 -12
32 -22 45 -22 13 0 33 10 45 22 19 21 20 34 20 248 0 188 -3 229 -16 248 -19
28 -67 29 -94 2z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
